(function () {
  'use strict';

  angular
    .module('neo.home.cancellations')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('home.cancellations', {
        url: '/cancellations',
        templateUrl: 'home/cancellations/cancellations.tpl.html',
        controller: 'CancellationsCtrl',
        controllerAs: 'vm',
        resolve: {
          townHalls: function (Ajuntaments) {
            return Ajuntaments.query().$promise;
          }
        }
      });
  }
}());
